import styled from "styled-components"

const MainButton = styled.button`
  font-family: "Articulat";
  font-weight: 900;
  text-transform: uppercase;
  color: white;
  cursor: pointer;
  font-size: 11px;
  transition: 0.3s ease;
  /* background: #b18b3b; */
  background: rgb(188, 158, 94);
  background: linear-gradient(
    180deg,
    rgba(188, 158, 94, 1) 0%,
    rgba(218, 183, 107, 1) 100%
  );
  border: none;
  padding: 10px 20px;
  border-radius: 40px;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);

  :disabled {
    background: gray;
  }

  :hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1);
  }

  :focus {
    outline: none;
  }
`

export default MainButton
